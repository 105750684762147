<script setup lang="ts">
import type { Game } from "@/types";
defineEmits<{ (event: "toggleFavorite"): void }>();

type gridBreakpoints = {
	full?: number;
	sm?: number;
	md?: number;
	lg?: number;
};

const props = withDefaults(
	defineProps<{
		game: Game;
		gameImg?: string;
		gameImgIncrease?: number;
		width?: number | gridBreakpoints;
		height?: number | gridBreakpoints;
		showGameTitle?: boolean;
		isFavorite?: boolean;
		defaultBg?: string;
	}>(),
	{
		gameImgIncrease: 1,
		width: 160,
		height: 200
	}
);

const { theme } = useTheme();

const isGuest = useIsGuest();
const formatRef = ref("webp");
const prepareImgUrl = useImage();

const defaultImgCss = computed(
	() =>
		`url(${prepareImgUrl(props.defaultBg ? props.defaultBg : `/img/game-cards/pattern/${theme.value}.png`, {
			format: "webp",
			width: 200
		})})`
);

const alt = computed(() => removeHtmlTags(props.game?.title || ""));
const computeSize = (key: keyof gridBreakpoints, sizeProp: number | gridBreakpoints) =>
	typeof sizeProp === "object" && sizeProp[key] ? `${sizeProp[key]}px` : null;

const width = computed(() => {
	if (typeof props.width === "number") {
		return `${props.width}px`;
	}
	if (typeof props.width === "object") {
		return props.width?.full ? `${props.width.full}px` : "160px";
	}
	return null;
});

const height = computed(() => {
	if (typeof props.height === "number") {
		return `${props.height}px`;
	}
	if (typeof props.height === "object") {
		return props.height?.full ? `${props.height.full}px` : "200px";
	}
	return null;
});

const widthNuxtImg = computed(() => {
	if (typeof props.width === "number") {
		return props.width * props.gameImgIncrease;
	}
	if (typeof props.width === "object" && props.width?.full) {
		return props.width.full * props.gameImgIncrease;
	}
	return 160;
});

const wSm = computed(() => computeSize("sm", props.width));
const wMd = computed(() => computeSize("md", props.width));
const wLg = computed(() => computeSize("lg", props.width));

const hSm = computed(() => computeSize("sm", props.height));
const hMd = computed(() => computeSize("md", props.height));
const hLg = computed(() => computeSize("lg", props.height));

onMounted(() => {
	if (props.gameImg?.includes(".gif")) {
		setTimeout(() => {
			formatRef.value = "gif";
		}, 300);
	}
});
</script>

<template>
	<div class="game-card" :data-tid="`gcard-${game.id || 0}`">
		<div
			:class="[
				'image-block',
				{
					'w-sm': wSm,
					'w-md': wMd,
					'w-lg': wLg,
					'h-sm': hSm,
					'h-md': hMd,
					'h-lg': hLg
				}
			]"
		>
			<div class="top-info">
				<slot name="top" />
			</div>
			<NuxtImg
				v-if="gameImg"
				:format="formatRef"
				:width="widthNuxtImg * gameImgIncrease"
				height="auto"
				:src="gameImg"
				loading="lazy"
				:alt="showGameTitle ? '' : alt"
			/>
			<div class="game-overlay" :data-tid="`gplay-${game.id || 0}`">
				<NuxtIcon name="games/play" filled />
			</div>
			<MGameFavorite v-if="!isGuest" :game="game" :isFavorite="isFavorite" @toggle-favorite="$emit('toggleFavorite')" />
		</div>
		<template v-if="showGameTitle">
			<AText class="game-title" :modifiers="['ellipsis']" as="p"><span class="title" v-html="game.title" /></AText>
		</template>
	</div>
</template>

<style scoped lang="scss">
$transition: all 200ms ease-out;

.game-card {
	position: relative;
	z-index: 1;
	cursor: pointer;
	.image-block {
		position: relative;
		z-index: 1;
		overflow: hidden;
		width: v-bind(width);
		height: v-bind(height);

		&.w-lg {
			@include media-breakpoint-down(lg) {
				width: v-bind(wLg);
			}
		}
		&.w-md {
			@include media-breakpoint-down(md) {
				width: v-bind(wMd);
			}
		}
		&.w-sm {
			@include media-breakpoint-down(sm) {
				width: v-bind(wSm);
			}
		}
		&.h-lg {
			@include media-breakpoint-down(lg) {
				height: v-bind(hLg);
			}
		}
		&.h-md {
			@include media-breakpoint-down(md) {
				height: v-bind(hMd);
			}
		}
		&.h-sm {
			@include media-breakpoint-down(sm) {
				height: v-bind(hSm);
			}
		}

		img {
			display: block;
			border-radius: inherit;
			width: 100%;
			height: 100%;
			will-change: transform;
			transition: $transition;
		}
		&::before {
			content: "";
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			width: 100%;
			max-width: 200px;
			background: v-bind(defaultImgCss) no-repeat center center / contain;
			background-size: 60% auto;
			margin: 0 auto;
			z-index: -1;
		}
	}
	.game-overlay {
		opacity: 0;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: $transition;
		:deep(.nuxt-icon) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.top-info {
		position: absolute;
		top: 8px;
		left: 8px;
		width: calc(100% - 8 * 2px);
		z-index: 1;
	}
	&:hover {
		img {
			transform: scale(1.15);
		}
		.game-overlay {
			opacity: 1;
		}
	}
}
</style>
